<template>
  <div class="container">
    <h4 class="text-center my-4">Список магистрантов/докторантов для добавление пререквизитов</h4>

    <PrimePreloader v-if="loading"/>
    <div v-else class="my-3">

      <DataTable :value="mastersPrerequisites" showGridlines stripedRows
                 :globalFilterFields="['last_name','first_name', 'barcode']"
                 v-model:filters="filters"
                 :paginator="true"
                 :rows="100"
                 paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                 :rowsPerPageOptions="[10,100,200]"
                 responsiveLayout="scroll"
                 currentPageReportTemplate="Показано с {first} по {last} из {totalRecords}">
        <template #header>
          <div class="p-d-flex p-jc-between p-ai-center">
            <span class="p-input-icon-left">
              <i class="pi pi-search"/>
              <InputText v-model="filters['global'].value" placeholder="Поиск"/>
            </span>
          </div>
        </template>
        <template #empty>
          Не найдено
        </template>
        <template #loading>
          Идет поиск. Пожалуйста подождите.
        </template>

        <Column header="№" :style="{width: '60px'}">
          <template #body="{index}">
            {{index+1}}
          </template>
        </Column>

        <Column header="Баркод" field="barcode"></Column>

        <Column header="ФИО">
          <template #body="{data}">
            {{data.last_name}} {{data.first_name}}
          </template>
        </Column>

        <Column header="ОП">
          <template #body="{data}">
            {{data.education_speciality_code}} {{data.education_speciality_name}} - {{data.language_name}}
          </template>
        </Column>

        <Column header="Действие">
          <template #body="{data}">
            <div v-if="data.saved_disciplines.length" class="rounded-3 border mb-2 p-2">
              <div class="mb-2 text-center fw-bold">Сохраненные</div>
              <ul>
                <li v-for="(d, dIndex) in data.saved_disciplines" :key="dIndex">
                  {{d.education_discipline_name}}
                </li>
              </ul>
            </div>


            <Button v-if="data.not_saved_disciplines.length||!data.saved_disciplines.length" icon="pi pi-list" label="Дисциплины" @click="openDisciplines(data.student_data_id)"
                    class="p-button-rounded"/>
          </template>
        </Column>
      </DataTable>


      <!-- disciplines -->
      <Dialog header="Дисциплины" :visible="displayDisciplines" :closable="false" :modal="true"
              :style="{width: '100%', maxWidth: '600px'}">

        <div v-if="disciplines.length" class="my-3">
          <div v-for="(item, index) in disciplines" :key="index" class="mb-2">
            <Checkbox :id="`discipline${item.education_discipline_id}`" name="disciplines"
                      :value="item.education_discipline_id"
                      v-model="checkedDisciplines"/>
            <label :for="`discipline${item.education_discipline_id}`" class="ms-2">
              {{item.education_discipline_name}}
            </label>
          </div>
        </div>
        <div v-else>Список дисциплин пуст</div>

        <template #footer>
          <Button label="Закрыть" icon="pi pi-times" @click="closeDisciplines" class="p-button-text"/>
          <Button label="Сохранить" icon="pi pi-save" @click="savePrerequisitesRatings"
                  :disabled="!checkedDisciplines.length"
                  autofocus/>
        </template>
      </Dialog>
      <!-- end disciplines -->

    </div>


  </div>
</template>

<script>
  import {mapActions, mapState} from 'vuex'
  import {FilterMatchMode, FilterOperator} from 'primevue/api'


  export default {
    name: 'PrerequisitesMagistracy',
    data() {
      return {
        loading: true,
        filters: {
          'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
          'last_name': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]
          },
          'first_name': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]
          },
          'barcode': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]
          },
        },
        displayDisciplines: false,
        checkedDisciplines: [],
        studentId: 0,
        disciplines: [],
      }
    },
    computed: {
      ...mapState('officeRegistrationPrerequisitesMagistracy', ['mastersPrerequisites'])
    },
    methods: {
      ...mapActions('officeRegistrationPrerequisitesMagistracy', ['GET_MASTERS_PREREQUISITES', 'POST_PREREQUISITES_RATINGS']),
      openDisciplines(studentId) {
        this.checkedDisciplines = []

        this.studentId = studentId
        this.disciplines = this.mastersPrerequisites.find(i => i.student_data_id === studentId)?.not_saved_disciplines || []

        this.displayDisciplines = true
      },
      closeDisciplines() {
        this.displayDisciplines = false
      },
      async savePrerequisitesRatings() {

        const form = this.checkedDisciplines.map(education_discipline_id => ({
          student_id: this.studentId,
          education_discipline_id
        }))

        const res = await this.POST_PREREQUISITES_RATINGS(form)
        if (res) {
          await this.GET_MASTERS_PREREQUISITES()
          this.closeDisciplines()
          this.$message({title: 'Успешно сохранено'})
        }
      }
    },
    async mounted() {
      await this.GET_MASTERS_PREREQUISITES()
      this.loading = false
    },
  }

</script>